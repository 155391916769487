export default function GTMScript() {
  if (!process.env.NEXT_PUBLIC_GTM) return null;

  const SCRIPT = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GTM}');`

  return (
    <script dangerouslySetInnerHTML={{ __html: SCRIPT }} />
  );
}

export function GTMNoScript() {
  if (!process.env.NEXT_PUBLIC_GTM) return null;

  return (
    <>
      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript><iframe src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GTM}`}
      height="0" width="0" style={{ display:'none', visibility: 'hidden' }}></iframe></noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}
    </>
  )
}
