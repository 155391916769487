export const setSideMenu = (value = false) => ({
  type: 'SET_SIDE_MENU',
  value,
});

export const setCompanyData = (value = null) => ({
  type: 'SET_COMPANY_DATA',
  value,
});

export const setUserData = (value = null) => ({
  type: 'SET_USER_DATA',
  value,
});

export const setModalCreateJob = (value = false) => ({
  type: 'SET_MODAL_CREATE_JOB',
  value,
});

export const setJobListPreview = (value = null) => ({
  type: 'SET_JOBLIST_PREVIEW',
  value,
});

export const setJobListModalCancel = (value = false) => ({
  type: 'SET_MODAL_CANCEL',
  value,
});

export const setJobList = (value = []) => ({
  type: 'SET_JOB_LIST',
  value,
});

export const setJobDetail = (value = null) => ({
  type: 'SET_JOB_DETAIL',
  value,
});

export const setApplicantDetail = (value = null) => ({
  type: 'SET_APPLICANT_DETAIL',
  value,
});

export const setLandingPageFetched = (value = true) => ({
  type: 'SET_LANDING_PAGE_FETCHED',
  value,
});
