export default function reducer(state, action) {
  switch (action.type) {
    case 'SET_SIDE_MENU':
      return {
        ...state,
        sideMenu: action.value,
      };
    case 'SET_COMPANY_DATA':
      return {
        ...state,
        company: action.value,
      };
    case 'SET_USER_DATA':
      return {
        ...state,
        user: action.value,
      };
    case 'SET_JOB_DETAIL':
      return {
        ...state,
        jobDetail: action.value,
      };
    case 'SET_APPLICANT_DETAIL':
      return {
        ...state,
        applicantDetail: action.value,
      };
    case 'SET_LANDING_PAGE_FETCHED':
      return {
        ...state,
        landingPage_fetched: action.value,
      };
    default:
      return state;
  }
}

export function jobListReducer(state, action) {
  switch (action.type) {
    case 'SET_MODAL_CREATE_JOB':
      return {
        ...state,
        modalCreateJob: action.value,
      };
    case 'SET_JOBLIST_PREVIEW':
      return {
        ...state,
        preview: action.value,
      };
    case 'SET_MODAL_CANCEL':
      return {
        ...state,
        modalCancel: action.value,
      };
    case 'SET_JOB_LIST':
      return {
        ...state,
        jobList: action.value,
      };
    default:
      return state;
  }
}
